import lang from 'select2/src/js/select2/i18n/es';
let shipping_state = $('select[name="shipping_state"]');
let billing_state = $('select[name="billing_state"]');
let region_id = $('select[name="region_id"]');

const changeCartCounter = (operation = 'inc') => {
    let counter = $('.cart-counter');
    let currentCount = parseInt(counter.html());
    if(operation === 'inc') {
        currentCount++;
    }
    else {
        currentCount--
    }
    counter.html(currentCount);
    if(currentCount === 0) {
        counter.addClass('d-none');
    }
    else {
        counter.removeClass('d-none');
    }
}

$(document).ready(function() {

    $('.same-height').matchHeight();
    $('.same-height-drop').matchHeight();

    $('.select2').select2({language: lang});

    $('.select2-tags').select2({
        language: lang,
        allowClear: false,
        tags: true,
        multiple: true,
        debug: true
    })

    $('.auto-open').modal('show');

    $('#submit-checkout').on('click', function(e) {
        $('#checkout_form').submit();
    })

});

$(document).on('submit','#add-to-cart-form', function (e) {

    e.preventDefault();
    let button = $(this).find('button');
    button.append('<div class="p-3 btn-spinner"><div class="spinner-border text-white" role="status"></div></div>');

    $.ajax({
        url: $(this).attr('action'),
        type: "post",
        data: $(this).serialize(),
    }).done( function(res) {
        button.find('.btn-spinner').remove();
        changeCartCounter();

        $('.cart-list-wrapper').html($(res).find('.cart-list-wrapper').html());
        $('#cart-sidebar').find('.cart').modal('show');
    }).fail( function(e, res, xhr) {
        button.find('.btn-spinner').remove();
    });

});


$(document).on('click','.remove-from-cart', function (e) {
    e.preventDefault();
    let parent = $(this).closest('li.cart-list-item');
    let subtotal = $('#cart-subtotal');
    parent.append('<div class="cart-item-loader"><div class="spinner-border text-warning" role="status"></div></div>');

    $.ajax({
        url: $(this).attr('href'),
        type: "get",
        content: 'json',
    }).done( function(res) {
        changeCartCounter('dec');
        $('.cart-list-wrapper').html($(res).find('.cart-list-wrapper').html());
    }).fail( function(e, res, xhr) {
        parent.find('.cart-item-loader').remove();
    });
})

$(document).on('change','.field-cart-qty', function (e) {
    let qty = $(this).val();
    const item = $(this).data('cart-item');

    let parent = $(this).closest('li.cart-list-item');
    parent.append('<div class="cart-item-loader"><div class="spinner-border text-warning" role="status"></div></div>');

    if(qty < 1) {
        qty = 1;
        $(this).val(1);
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: '/cart/update-quantity',
        type: "post",
        content: 'json',
        data: {quantity: qty, item: item}
    }).done( function(res) {
        updateCart(parent.find('.cart-item-loader'))
    }).fail( function(e, res, xhr) {
        console.log('error');
        parent.find('.cart-item-loader').remove()
    });
});


function updateCart(loader) {
    $.ajax({
        url: '/cart/update',
        type: "get",
    }).done( function(res) {
        loader.remove();
        $('#cart-sidebar-list').html(res);
    }).fail( function(e, res, xhr) {
        loader.remove();
        console.log(error);
    });
}

$.fn.imageGallery = function() {
    var parent = $(this);
    $(this).find('.product-image-thumbnail').on('click',function () {
        parent.find('.product-image-main img').attr('src',$(this).data('src'));
    });

};
$('.product-image-gallery').imageGallery();

const input = document.querySelector("#phone");
if(input) {
    let iti = window.intlTelInput(input, {
        initialCountry: "cl",
        separateDialCode: true,
        utilsScript: '/js/intl-tel-input/utils.js'
    });

// on blur: validate
    input.addEventListener('blur', function () {
        if (input.value.trim()) {
            if (iti.isValidNumber()) {
                console.log('valid');
            } else {
                $('input[name="phone"]').val('');
            }
        }
    });

    $('#phone').on('change', function () {
        $('input[name="phone"]').val(iti.getNumber());
        $('input[name="billing_phone"]').val(iti.getNumber());
    });
}

var slider_price = document.getElementsByClassName('slider_price');

if(slider_price.length > 0) {

    for (let i = 0, len = slider_price.length; i < len; i++) {
        const sliderItem = slider_price[i];
        window.noUiSlider.create(sliderItem, {
            start: [sliderItem.dataset.currentPriceMin, sliderItem.dataset.currentPriceMax],
            connect: true,
            range: {
                'min': parseInt(sliderItem.dataset.priceMin),
                'max': parseInt(sliderItem.dataset.priceMax)
            }
        });

        sliderItem.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
            $('span.price-min').html(formatter.format(values[0]));
            $('span.price-max').html(formatter.format(values[1]));
            $('input.price-min').val(parseInt(values[0]));
            $('input.price-max').val(parseInt(values[1]));
        });
    }
}

var slider_pages = document.getElementsByClassName('slider_pages');
if(slider_pages.length > 0) {
    for (let i = 0, len = slider_pages.length; i < len; i++) {
        const sliderItem = slider_pages[i];

        window.noUiSlider.create(sliderItem, {
            start: [sliderItem.dataset.currentPagesMin, sliderItem.dataset.currentPagesMax],
            connect: true,
            range: {
                'min': parseInt(sliderItem.dataset.pagesMin),
                'max': parseInt(sliderItem.dataset.pagesMax)
            }
        });

        sliderItem.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
            $('span.pages-min').html(parseInt(values[0]));
            $('span.pages-max').html(parseInt(values[1]));
            $('input.pages-min').val(parseInt(values[0]));
            $('input.pages-max').val(parseInt(values[1]));
        });
    }
}

const notificationButton = $('.notification-button');
notificationButton.on('click', function (e) {
    $(this).find('span').remove();

    $.ajax({
        url: $(this).data('href'),
        type: "get",
    }).done( function(res) {

    }).fail( function(e, res, xhr) {

    });
});


const helpMenu = $('#help-menu');
const helpContent = $('#help-content');
helpMenu.find('a').on('click', function (e) {
    $('html,body').animate({
        scrollTop: helpContent.offset().top - 30
    }, 'slow');
});

if(shipping_state.val() != null && shipping_state.val() !== '') {
    let $city_select = $('select[name="shipping_city"]');
    if($city_select.val()) {
        $city_select.prop('disabled',false);
    }
    else {
        loadCommunesByRegion(shipping_state.val(), $city_select);
    }
}

shipping_state.on('change', function (e) {
    const val = $(this).val();
    let $city_select = $('select[name="shipping_city"]');
    loadCommunesByRegion(val, $city_select);
});

if(billing_state.val() != null && billing_state.val() !== '') {
    let $city_select = $('select[name="billing_city"]');
    if($city_select.val()) {
        $city_select.prop('disabled',false);
    }
    else {
        loadCommunesByRegion(billing_state.val(), $city_select);
    }
}

billing_state.on('change', function (e) {
    const val = $(this).val();
    let $city_select = $('select[name="billing_city"]');
    loadCommunesByRegion(val, $city_select);
});

if(region_id.val() != null && region_id.val() !== '') {
    let $city_select = $('select[name="commune_id"]');
    if($city_select.val()) {
        $city_select.prop('disabled',false);
    }
    else {
        loadCommunesByRegion(region_id.val(), $city_select);
    }
}

region_id.on('change', function (e) {
    const val = $(this).val();
    let $city_select = $('select[name="commune_id"]');
    loadCommunesByRegion(val, $city_select);
});


function loadCommunesByRegion(regionId, $select) {

    $.ajax({
        url: '/communes-by-region/'+regionId,
        type: "get",
        content: 'json',
    }).done( function(res) {
        $select.prop('disabled',false)
        $select.html("");
        $select.append('<option>Seleccione</option>');
        $.each(res, function (index,val) {
            $select.append('<option value=' + val.id + '>' + val.name + '</option>');
        })
    }).fail( function(e, res, xhr) {
        $select.prop('disabled',true)
        console.log('error');
    });
}

$('#modal-terms-accept').on('click', function(e) {
    e.preventDefault();
    $('input[name="terms"]').prop('checked',true);
    $('#modal-terms').modal('hide');
});

