let shipping_address_id = $('input[name="shipping_address_id"]');
let shipping_method = $('#shipping_method');
let another_shipping = $('input#another_shipping');
let another_shipping_hidden = $('input#another_shipping[type="hidden"]');
let another_shipping_wrapper = $('.another-shipping');
let shipping_state = $('select[name="shipping_state"]');
let shipping_city = $('select[name="shipping_city"]');

let billing_address_1 = $('input[name="billing_address_1"]');
let billing_address_2 = $('input[name="billing_address_2"]');
let billing_state = $('select[name="billing_state"]');
let billing_city = $('select[name="billing_city"]');

let billing_rut = $('input[name="billing_rut"]');
let billing_company = $('input[name="billing_company"]');
let billing_company_activity = $('input[name="billing_company_activity"]');

let document_type = $('select[name="document_type"]');
let checkoutForm = $('#checkout_form');
let has_other_shipping = false;
let fields = $('.shipping_fields');
let shipping_fields_wrapper = $('.shipping_fields_wrapper');

let receiver_option = $('input[name="receiver_option"]');
let receiver_name_wrapper = $('#receiver_name_wrapper');

if(checkoutForm.length) {
    updateCheckoutTotals();
}

receiver_option.on('change', function() {
    if($(this,':checked').val() === 'receiver_other'){
        receiver_name_wrapper.removeClass('d-none');
    }
    else {
        receiver_name_wrapper.addClass('d-none');
    }
})

if(another_shipping.is(':checked')) {
    fields.show();
    shipping_address_id.prop('checked', false);

    has_other_shipping = true;
}
else if(another_shipping_hidden.length && another_shipping_hidden.val() == 1) {
    has_other_shipping = true;
}
else {
    fields.hide();
}

if(shipping_method.val() === 'order.shipping_method_delivery') {
    shipping_fields_wrapper.show();
}
else {
    shipping_fields_wrapper.hide();
}

if(document_type.val() !== 'order.invoice') {
    billing_rut.closest('.form-group').hide();
    billing_company.closest('.form-group').hide();
    billing_company_activity.closest('.form-group').hide();
    billing_address_1.closest('.form-group').hide();
    billing_address_2.closest('.form-group').hide();
    billing_city.closest('.form-group').hide();
    billing_state.closest('.form-group').hide();
}

document_type.on('change',function () {
    if($(this).val()==='order.invoice') {
        billing_rut.closest('.form-group').show();
        billing_company.closest('.form-group').show();
        billing_company_activity.closest('.form-group').show();
        billing_address_1.closest('.form-group').show();
        billing_address_2.closest('.form-group').show();
        billing_city.closest('.form-group').show();
        billing_state.closest('.form-group').show();
    }
    else {
        billing_rut.closest('.form-group').hide();
        billing_company.closest('.form-group').hide();
        billing_company_activity.closest('.form-group').hide();
        billing_address_1.closest('.form-group').hide();
        billing_address_2.closest('.form-group').hide();
        billing_city.closest('.form-group').hide();
        billing_state.closest('.form-group').hide();
    }

});

another_shipping.on('change',function () {
    if($(this).is(':checked')) {
        fields.show();
        shipping_address_id.prop('checked', false);
        has_other_shipping = true;

        updateCheckoutTotals();

    }
    else {
        fields.hide();
        has_other_shipping = false;

        updateCheckoutTotals();
    }
});

shipping_address_id.on('change', function () {
    if(another_shipping.prop('checked')) {
        another_shipping.prop('checked', false)
        another_shipping.trigger('change');
    }

    updateCheckoutTotals();
})

$(billing_city).on('change', function() {
    //if(!has_other_shipping && isBillingAddressFull()) {
    //    updateCheckoutTotals();
    //}
})
$(billing_state).on('change', function() {
    billing_city.val('').trigger('change');
})

$(shipping_city).on('change', function() {
    console.log(has_other_shipping);
    if(has_other_shipping && isShippingAddressFull()) {
        updateCheckoutTotals();
    }
})
$(shipping_state).on('change', function() {
    shipping_city.val('');
})

$(document).on('change','select[name="shipping_method"]', function () {
    if($(this).val() === 'order.shipping_method_delivery') {
        shipping_fields_wrapper.show();
        $('#message_delivery').removeClass('d-none')
        $('#message_local_pickup').addClass('d-none')
    }
    else {
        shipping_fields_wrapper.hide();
        $('#message_local_pickup').removeClass('d-none')
        $('#message_delivery').addClass('d-none')

    }
    updateCheckoutTotals();
})

function isShippingAddressFull() {
    return (shipping_state.val() && shipping_city.val());
}

function isBillingAddressFull() {
    return (billing_state.val() && billing_city.val());
}

function updateCheckoutTotals() {
    let data = checkoutForm.serialize();
    let url = '/checkout/update_totals';
    let container = $('.checkout-order-totals');
    if(container.find('.cart-item-loader').length === 0) {
        container.append('<div class="cart-item-loader"><div class="spinner-border text-warning" role="status"></div></div>');
    }

    $.ajax({
        url: url,
        type: "post",
        data: data,
    }).done( function(res) {
        setTimeout(function() {
            container.html(res);
        },1000)
    }).fail( function(e, res, xhr) {
        container.find('.cart-item-loader').remove();
        console.log('error');
    });
}

$(document).on('change','.checkout-field-cart-qty', function (e) {
    let qty = $(this).val();
    const item = $(this).data('cart-item');

    if(qty < 1) {
        qty = 1;
        $(this).val(1);
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $.ajax({
        url: '/cart/update-quantity',
        type: "post",
        content: 'json',
        data: {quantity: qty, item: item}
    }).done( function(res) {
        console.log(res);
        updateCheckoutTotals();
    }).fail( function(e, res, xhr) {
        console.log('error');
    });
});

$('input,select').on('change', function() {
    $(this).removeClass('is-invalid');
    $(this).parent().removeClass('is-invalid')
})
